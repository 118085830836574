/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TestSummaryItemDTO,
    TestSummaryItemDTOFromJSON,
    TestSummaryItemDTOFromJSONTyped,
    TestSummaryItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface TestSummaryDTO
 */
export interface TestSummaryDTO {
    /**
     * 
     * @type {number}
     * @memberof TestSummaryDTO
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof TestSummaryDTO
     */
    averageScore: number;
    /**
     * 
     * @type {Array<TestSummaryItemDTO>}
     * @memberof TestSummaryDTO
     */
    interpretationItems?: Array<TestSummaryItemDTO>;
}

export function TestSummaryDTOFromJSON(json: any): TestSummaryDTO {
    return TestSummaryDTOFromJSONTyped(json, false);
}

export function TestSummaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestSummaryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'averageScore': json['averageScore'],
        'interpretationItems': !exists(json, 'interpretationItems') ? undefined : ((json['interpretationItems'] as Array<any>).map(TestSummaryItemDTOFromJSON)),
    };
}

export function TestSummaryDTOToJSON(value?: TestSummaryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'averageScore': value.averageScore,
        'interpretationItems': value.interpretationItems === undefined ? undefined : ((value.interpretationItems as Array<any>).map(TestSummaryItemDTOToJSON)),
    };
}


