/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TestResultDetailsDTO
 */
export interface TestResultDetailsDTO {
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    highResponseTimeCount: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    lowResponseTimePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    practiceSTD: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    testSTD: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    practiceMeanDiff: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    testMeanDiff: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    practiceEffectSize: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    testEffectSize: number;
}

export function TestResultDetailsDTOFromJSON(json: any): TestResultDetailsDTO {
    return TestResultDetailsDTOFromJSONTyped(json, false);
}

export function TestResultDetailsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestResultDetailsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'highResponseTimeCount': json['highResponseTimeCount'],
        'lowResponseTimePercentage': json['lowResponseTimePercentage'],
        'practiceSTD': json['practiceSTD'],
        'testSTD': json['testSTD'],
        'practiceMeanDiff': json['practiceMeanDiff'],
        'testMeanDiff': json['testMeanDiff'],
        'practiceEffectSize': json['practiceEffectSize'],
        'testEffectSize': json['testEffectSize'],
    };
}

export function TestResultDetailsDTOToJSON(value?: TestResultDetailsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'highResponseTimeCount': value.highResponseTimeCount,
        'lowResponseTimePercentage': value.lowResponseTimePercentage,
        'practiceSTD': value.practiceSTD,
        'testSTD': value.testSTD,
        'practiceMeanDiff': value.practiceMeanDiff,
        'testMeanDiff': value.testMeanDiff,
        'practiceEffectSize': value.practiceEffectSize,
        'testEffectSize': value.testEffectSize,
    };
}


