/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessToken,
    AccessTokenFromJSON,
    AccessTokenToJSON,
    AuthenticatedModel,
    AuthenticatedModelFromJSON,
    AuthenticatedModelToJSON,
    ImplicitBiasResultModel,
    ImplicitBiasResultModelFromJSON,
    ImplicitBiasResultModelToJSON,
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from '../models';

export interface UserAuthenticateRequest {
    info: AuthenticatedModel;
}

export interface UserCreateAccountRequest {
    info: UserInfo;
}

export interface UserGenerateUrlTokenRequest {
    userId: string;
}

export interface UserGetUserByTokenRequest {
    token?: string | null;
}

export interface UserLoginRequest {
    info: AuthenticatedModel;
}

export interface UserPreviousResultsRequest {
    userId: string;
}

export interface UserSaveResultsRequest {
    info: ImplicitBiasResultModel;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async userAuthenticateRaw(requestParameters: UserAuthenticateRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling userAuthenticate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/User/IsAuthenticated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticatedModelToJSON(requestParameters.info),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async userAuthenticate(requestParameters: UserAuthenticateRequest): Promise<boolean> {
        const response = await this.userAuthenticateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userCreateAccountRaw(requestParameters: UserCreateAccountRequest): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling userCreateAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/User/CreateAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInfoToJSON(requestParameters.info),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     */
    async userCreateAccount(requestParameters: UserCreateAccountRequest): Promise<UserInfo> {
        const response = await this.userCreateAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGenerateUrlTokenRaw(requestParameters: UserGenerateUrlTokenRequest): Promise<runtime.ApiResponse<AccessToken>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userGenerateUrlToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/User/GenerateToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userId as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenFromJSON(jsonValue));
    }

    /**
     */
    async userGenerateUrlToken(requestParameters: UserGenerateUrlTokenRequest): Promise<AccessToken> {
        const response = await this.userGenerateUrlTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGetUserByTokenRaw(requestParameters: UserGetUserByTokenRequest): Promise<runtime.ApiResponse<UserInfo>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/User/GetUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     */
    async userGetUserByToken(requestParameters: UserGetUserByTokenRequest): Promise<UserInfo> {
        const response = await this.userGetUserByTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userLoginRaw(requestParameters: UserLoginRequest): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling userLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/User/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticatedModelToJSON(requestParameters.info),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     */
    async userLogin(requestParameters: UserLoginRequest): Promise<UserInfo> {
        const response = await this.userLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userPreviousResultsRaw(requestParameters: UserPreviousResultsRequest): Promise<runtime.ApiResponse<Array<ImplicitBiasResultModel>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userPreviousResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/User/PreviousResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userId as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImplicitBiasResultModelFromJSON));
    }

    /**
     */
    async userPreviousResults(requestParameters: UserPreviousResultsRequest): Promise<Array<ImplicitBiasResultModel>> {
        const response = await this.userPreviousResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userSaveResultsRaw(requestParameters: UserSaveResultsRequest): Promise<runtime.ApiResponse<ImplicitBiasResultModel>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling userSaveResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/User/SaveResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImplicitBiasResultModelToJSON(requestParameters.info),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImplicitBiasResultModelFromJSON(jsonValue));
    }

    /**
     */
    async userSaveResults(requestParameters: UserSaveResultsRequest): Promise<ImplicitBiasResultModel> {
        const response = await this.userSaveResultsRaw(requestParameters);
        return await response.value();
    }

}
