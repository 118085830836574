import { getCookie } from 'src/utilities/cookies';
import { trackPromise } from 'react-promise-tracker';
import { ConfigurationParameters, Configuration, AnalysisApi, AnalyzeImplicitBiasAnalysisInfo, AnalysisAnalyzeRequest } from '../../external-api/service-api';

const apiUrl = `${process.env.GATSBY_API_URL}`;
const apiKey = `${process.env.GATSBY_OPENAPI_API_KEY}`;

function getApi() {
    const token = getCookie("token");
    const configParams: ConfigurationParameters = {
        basePath: apiUrl,
        headers: {
            "apikey": apiKey,
            "Authorization": `Bearer ${token}`
        },
    };

    const configuration = new Configuration(configParams);
    return new AnalysisApi(configuration);
}

export async function getImplicitBiasResults(data: AnalyzeImplicitBiasAnalysisInfo) {
    const api = getApi();
    const request: AnalysisAnalyzeRequest = {
        info: data
    }
    return await trackPromise(api.analysisAnalyze(request));
}

