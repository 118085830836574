import palette from './palette';

export default {
    body1: {
        fontSize: "0.975rem",
    },
    body2: {
        fontSize: "0.975rem",
    },
    h1: {
        marginBottom: "20.125rempx",
        marginTop: "0.625rem",
        paddingBottom: "0.9375rem",
        color: palette.text.primary,
        fontSize: "1.6em",
        fontWeight: 100,
        borderBottom:"0.0625rem solid black",
    },
    h2: {
        margin: "1rem 0",
        color: palette.text.primary,
        fontWeight: 500,
        letterSpacing: '-0.015em',
        lineHeight: '1.75rem',
        fontFamily: "'Roboto Condensed'",
        fontSize: "1.4875rem",
    },
    h3: {
        margin: "1.25rem 0",
        color: palette.text.primary,
        fontWeight: 400,
        fontFamily: "Roboto Condensed",
        fontSize: "1.1875rem",
        letterSpacing: '-0.015em',
        lineHeight: '1.75rem',
    },
    h4: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '1.25rem',
        letterSpacing: '-0.015em',
        lineHeight: '1.5rem'
    },
    h5: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '1rem',
        letterSpacing: '-0.015em',
        lineHeight: '1.25rem'
    },
    h6: {
        color: palette.text.primary,
        fontWeight: 500,
        letterSpacing: '-0.015em',
        lineHeight: '2.1rem',
        fontFamily: "'Roboto Condensed'",
        fontSize: "1.6875rem",
        textAlign: 'center'
    },
    subtitle1: {
        color: palette.text.primary,
        fontSize: '1rem',
        letterSpacing: '-0.015em',
        lineHeight: '1.5625rem'
    },
    subtitle2: {
        color: palette.text.secondary,
        fontWeight: 400,
        fontSize: '0.875rem',
        letterSpacing: '-0.015em',
        lineHeight: '1.3125rem'
    },
    paragraph: {
        marginBottom: "1.5em",
        fontSize: "2.75rem",
    },
    button: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: 1.4,
    },
    caption: {
        color: palette.text.secondary,
        fontSize: '0.6875rem',
        letterSpacing: '0.008em',
        lineHeight: '0.8125rem'
    }
};
