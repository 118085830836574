import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { loadState, saveState } from "src/utilities/persistence";
import lmsSliceReducer, { ILmsState } from "./features/lms/lmsSlice";
import surveySliceReducer, { ISurveyState } from "./features/survey/surveySlice";
import uiSliceReducer, { IUiState } from "./features/ui/uiSlice";
import userSliceReducer, { IUserState } from "./features/user/userSlice";

export interface IApplicationState {
    lmsStore: ILmsState
    uiStore: IUiState
    surveyStore: ISurveyState
    userStore: IUserState
}

const persistedState = loadState();


const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === "@@user/logout") { // check for action type 
        localStorage.clear();
        state = undefined;
    }
    return combinedReducer(state, action);
};

const combinedReducer = combineReducers({
    lmsStore: lmsSliceReducer,
    uiStore: uiSliceReducer,
    surveyStore: surveySliceReducer,
    userStore: userSliceReducer
});

const store = configureStore<IApplicationState>({
    reducer: rootReducer,
    preloadedState: persistedState,
    devTools: process.env.NODE_ENV !== "development" ? false : true,
});

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

store.subscribe(() => {
    saveState(store.getState());
})

export default store;