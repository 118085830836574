import { colors } from '@material-ui/core';

const black = '#000000';
const blackGrey = "#202020";
const orangeDark = "#C84C04";
const orangeDarker = "#AF4103";
const orangeLight = "#EE6029";
const orangeMain = "#DB5205";
const white = '#FFFFFF';
const whiteOff = '#f6f6f6';

export default {
  black,
  orangeDark,
  orangeDarker,
  orangeLight,
  orangeMain,
  white,
  whiteOff,
  primary: {
    main: orangeMain,
    contrastText: white,
  },
  secondary: {
    main: white,
    contrastText: orangeMain,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: blackGrey,
    secondary: blackGrey,
    link: orangeDark,
    header: orangeMain
  },
  background: {
    paper: white
  },
  icon: orangeMain,
  divider: blackGrey,
  colors: {
    orangeDarker: orangeDarker,
  }
};
