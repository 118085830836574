import palette from '../palette';

export default {
    root: {
        fontSize: "1rem",

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "#707070",
            borderRadius: 0,
            borderWidth: "0.0675rem",
        },

        '& .MuiFormHelperText-root': {
            margin: "-0.8rem 0 0 0",
            fontSize: "0.825rem",
            textAlign: "right",
        },

        '& .MuiInputBase-input': {
            fontSize: "1rem",
        }
    },
  };
  