/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ImplicitBiasInterpretationDirection {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export function ImplicitBiasInterpretationDirectionFromJSON(json: any): ImplicitBiasInterpretationDirection {
    return ImplicitBiasInterpretationDirectionFromJSONTyped(json, false);
}

export function ImplicitBiasInterpretationDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImplicitBiasInterpretationDirection {
    return json as ImplicitBiasInterpretationDirection;
}

export function ImplicitBiasInterpretationDirectionToJSON(value?: ImplicitBiasInterpretationDirection | null): any {
    return value as any;
}

