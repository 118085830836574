/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImplicitBiasResultModel
 */
export interface ImplicitBiasResultModel {
    /**
     * 
     * @type {string}
     * @memberof ImplicitBiasResultModel
     */
    userId?: string;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultModel
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultModel
     */
    preferenceIntensity: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultModel
     */
    preferenceType: number;
    /**
     * 
     * @type {Date}
     * @memberof ImplicitBiasResultModel
     */
    completedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ImplicitBiasResultModel
     */
    surveyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImplicitBiasResultModel
     */
    implicitBiasType?: string;
    /**
     * 
     * @type {string}
     * @memberof ImplicitBiasResultModel
     */
    implicitBiasId?: string;
}

export function ImplicitBiasResultModelFromJSON(json: any): ImplicitBiasResultModel {
    return ImplicitBiasResultModelFromJSONTyped(json, false);
}

export function ImplicitBiasResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImplicitBiasResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'score': json['score'],
        'preferenceIntensity': json['preferenceIntensity'],
        'preferenceType': json['preferenceType'],
        'completedDate': (new Date(json['completedDate'])),
        'surveyId': !exists(json, 'surveyId') ? undefined : json['surveyId'],
        'implicitBiasType': !exists(json, 'implicitBiasType') ? undefined : json['implicitBiasType'],
        'implicitBiasId': !exists(json, 'implicitBiasId') ? undefined : json['implicitBiasId'],
    };
}

export function ImplicitBiasResultModelToJSON(value?: ImplicitBiasResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'score': value.score,
        'preferenceIntensity': value.preferenceIntensity,
        'preferenceType': value.preferenceType,
        'completedDate': (value.completedDate.toISOString()),
        'surveyId': value.surveyId,
        'implicitBiasType': value.implicitBiasType,
        'implicitBiasId': value.implicitBiasId,
    };
}


