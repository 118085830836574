/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImplicitBiasInputDTO
 */
export interface ImplicitBiasInputDTO {
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasInputDTO
     */
    responseTimeMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasInputDTO
     */
    blockID: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasInputDTO
     */
    trialID: number;
    /**
     * 
     * @type {boolean}
     * @memberof ImplicitBiasInputDTO
     */
    correct: boolean;
}

export function ImplicitBiasInputDTOFromJSON(json: any): ImplicitBiasInputDTO {
    return ImplicitBiasInputDTOFromJSONTyped(json, false);
}

export function ImplicitBiasInputDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImplicitBiasInputDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responseTimeMilliseconds': json['responseTimeMilliseconds'],
        'blockID': json['blockID'],
        'trialID': json['trialID'],
        'correct': json['correct'],
    };
}

export function ImplicitBiasInputDTOToJSON(value?: ImplicitBiasInputDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responseTimeMilliseconds': value.responseTimeMilliseconds,
        'blockID': value.blockID,
        'trialID': value.trialID,
        'correct': value.correct,
    };
}


