import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IApplicationState } from "src/state/store";
import { CourseResults, ValidationErrors } from "src/type/globals";
import { saveCourseResult } from "../user/userSlice";
import { navigate } from "gatsby";
import { Paths } from "src/utilities/constants";
import { enqueueNotification } from "../ui/uiSlice";
import { CourseResultDTO } from "../../../../external-api/license-api";
import { addCourseResultToUser, addPublicCourseResultToUser } from "src/services/licenseService";
import { v4 as uuidv4 } from 'uuid';

export interface ICourseScore {
    correctAnswersCount: number,
    totalAnswersCount: number,
    passThreshold: number,
}

export interface ILmsState {
    currentCourse: {
        courseId: string
        contentfulId: string
        slug: string
    }
    currentScore: ICourseScore
}

const initialState: ILmsState = {
    currentCourse: {
        courseId: "",
        contentfulId: "",
        slug: "",
    },
    currentScore: {
        correctAnswersCount: 0,
        totalAnswersCount: 0,
        passThreshold: 0.8,
    }
}


// -- If the actions become too many, move them to a separate action file. 
const saveCourseResultAction = createAsyncThunk<
    undefined,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        '@@lms/saveCourse',
        async (data, thunkAPI) => {
            // -- Used for any extra dispatches or if u need to grab data from the current store state
            const { rejectWithValue, dispatch, getState } = thunkAPI;
            try {
                const { lmsStore, userStore: { user, isFreeUser, isAnonymousUser, testInfo } } = getState();
                const { currentCourse, currentScore } = lmsStore;
                const passed = currentScore.correctAnswersCount / currentScore.totalAnswersCount >= currentScore.passThreshold;

                const requestBody: CourseResultDTO = {
                    completedDate: new Date(),
                    courseId: currentCourse?.contentfulId,
                    score: currentScore.correctAnswersCount / currentScore.totalAnswersCount,
                    passed,
                    anonymousUser: isAnonymousUser,
                    groupId: testInfo.groupId,
                    scheduleId: testInfo.scheduleId,
                };

                if (isFreeUser) {
                    const res: CourseResultDTO = await addPublicCourseResultToUser(requestBody);

                    const response = {
                        completedDate: res.completedDate.toDateString(),
                        score: res.score,
                        feedback: res.feedback,
                        passed: res.passed,
                        courseId: res.courseId,
                    } as CourseResults

                    dispatch(saveCourseResult(response));
                }
                else {
                    if (isAnonymousUser) {
                        requestBody.participantId = uuidv4();
                    } else {
                        requestBody.participantId = user.publicID;
                    }

                    const res: CourseResultDTO = await addCourseResultToUser(requestBody);

                    const response = {
                        completedDate: res.completedDate.toDateString(),
                        score: res.score,
                        feedback: res.feedback,
                        passed: res.passed,
                        courseId: res.courseId,
                    } as CourseResults

                    dispatch(saveCourseResult(response));
                }

                navigate(Paths.COURSE_RESULTS);
            }
            catch (err) {
                if (!err.status) {
                    throw err
                }
                dispatch(enqueueNotification({
                    message: 'An error has Occurred',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));

                return rejectWithValue(err.body)
            }
        }
    );


// -- If the actions become too many, move them to a separate action file. 
const getAllCourseResultAction = createAsyncThunk<
    undefined,
    string,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        '@@lms/getAllCourseResults',
        async (userId, thunkAPI) => {
            // -- Used for any extra dispatches or if u need to grab data from the current store state
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                // const res: CourseResultModel[] = await getPreviousCourseResults(userId);
                // const res: CourseResultModel[] = 
                // const response = res.map<CourseResults>(x => {
                //     return {
                //         userid: x.userId,
                //         completedDate: x.completedDate.toDateString(),
                //         score: x.score,
                //         feedback: x.feedback,
                //         passed: x.passed,
                //         courseId: x.courseId,
                //         implicitBiasId: x.implicitBiasId
                //     }
                // })
                // dispatch(refreshCourseResult(response));
            }
            catch (err) {
                if (!err.status) {
                    throw err
                }

                dispatch(enqueueNotification({
                    message: 'Unable to fetch course results',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
                return rejectWithValue(err.body)
            }
        }
    );


const lmsSlice = createSlice({
    name: "@@lms",
    initialState,
    reducers: {
        setCourse(state, action) {
            state.currentCourse = action.payload
        },
        correctAnswer(state) {
            state.currentScore.totalAnswersCount++;
            state.currentScore.correctAnswersCount++;
        },
        wrongAnswer(state) {
            state.currentScore.totalAnswersCount++;
        },
        resetAnswers(state, action) {
            state.currentScore.correctAnswersCount = 0;
            state.currentScore.totalAnswersCount = 0;
            if (action.payload.passThreshold >= 0.0 && action.payload.passThreshold <= 1.0)
                state.currentScore.passThreshold = action.payload.passThreshold;
            else
                state.currentScore.passThreshold = 0.8
        }
    },
    extraReducers: () => { }
})

export const { correctAnswer, wrongAnswer, resetAnswers, setCourse } = lmsSlice.actions
export {
    saveCourseResultAction,
    getAllCourseResultAction
}
export default lmsSlice.reducer