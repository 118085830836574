import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthenticateModel, ParticipantInfo } from "../../../../external-api/service-api";
import { navigate } from "gatsby";
import { IApplicationState } from "src/state/store";
import { CourseResults, ImplicitBiasResults, ValidationErrors } from "src/type/globals";
import { Paths } from "src/utilities/constants";
import { setCookie } from "src/utilities/cookies";
import { UserInfo } from "../../../../external-api/user-api";
import { enqueueNotification } from "../ui/uiSlice";

import { v4 as uuidv4 } from 'uuid';
import { fetchPackageConfiguration, fetchPackageConfigurationBySchedule } from "src/services/licenseService";
import { login, createAccount } from "src/services/userService";
import { TestTakerScheduleDTO } from "external-api/license-api";

interface IUserResults {
    survey: ImplicitBiasResults[]
    course: CourseResults[]
}

export interface IUserState {
    user: UserInfo
    results: IUserResults
    isFreeUser: boolean
    isAnonymousUser: boolean
    isAuthenticated: boolean
    scheduleId: string
    testInfo: TestTakerScheduleDTO
}

const initialState: IUserState = {
    user: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        password: "",
        publicID: "",
        token: ""
    },
    results: {
        survey: [],
        course: []
    },
    isAuthenticated: false,
    isFreeUser: true,
    isAnonymousUser: false,
    scheduleId: "",
    testInfo: {
        groupId: "",
        scheduleId: "",
        testConfigurations: [],
        courseConfigurations: [],
        participantId: "",
        courseResults: [],
        testResults: []
    }
}

const loginAction = createAsyncThunk<
    undefined,
    AuthenticateModel,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        '@@user/login',
        async (user, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                const res: ParticipantInfo = await login(user);
                const token = res.token;

                if (res != null) {
                    setCookie("token", token!);

                    dispatch(enqueueNotification({
                        message: 'Successfully logged in',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        }
                    }));

                    dispatch(loginSuccess(res));

                    navigate(Paths.PREVIOUS_RESULTS);
                }
            }
            catch (err) {
                if (!err.status) {
                    throw err
                }
                dispatch(enqueueNotification({
                    message: 'You have entered an incorrect username/password',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
                return rejectWithValue(err.body)
            }
        }
    );

const createAccountAction = createAsyncThunk<
    undefined,
    UserInfo,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        "@@users/create-account",
        async (user, thunkAPI) => {
            const { dispatch, rejectWithValue } = thunkAPI;
            const res: UserInfo = await createAccount(user);
            const token = res.token;
            if (res != null) {
                try {
                    setCookie("token", token!);
                    dispatch(enqueueNotification({
                        message: 'Successfully created account',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        }
                    }));

                    dispatch(createAccountSuccess(res))
                    navigate(Paths.PREVIOUS_RESULTS);
                }
                catch (err) {

                    if (!err.status) {
                        throw err
                    }
                    switch (err.status) {
                        case 409: {
                            dispatch(enqueueNotification({
                                message: 'There is an account with this email already.  Please change your email.',
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'error'
                                }
                            }));
                            break;
                        }
                        default: {
                            dispatch(enqueueNotification({
                                message: 'There was an issue with creating the account',
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'error'
                                }
                            }));
                        }
                    }
                    return rejectWithValue(err.body)
                }

            }
        }
    );


const getUserPackages = createAsyncThunk<
    undefined,
    string,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        "@@users/get-user-package",
        async (id, thunkAPI) => {
            const { dispatch, rejectWithValue } = thunkAPI
            try {
                const testTakerSchedule = await fetchPackageConfiguration(id);

                if (testTakerSchedule) {
                    dispatch(setTestInfo(testTakerSchedule));
                    // dispatch(setHistoricalResults(testTakerSchedule));
                    dispatch(setParticipantID(testTakerSchedule.participantId));
                    // dispatch(setPackageConfiguration(testTakerSchedule));
                }
            }
            catch (err) {
                if (!err.status) {
                    throw err
                }
                return rejectWithValue(err.body)
            }
        }
    );


const getUserPackagesBySchedule = createAsyncThunk<
    undefined,
    string,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        "@@users/get-user-package-by-schedule",
        async (id, thunkAPI) => {
            const { dispatch, rejectWithValue } = thunkAPI
            try {
                const testTakerSchedule = await fetchPackageConfigurationBySchedule(id);

                if (testTakerSchedule) {
                    const participantID = uuidv4();

                    dispatch(setTestInfo(testTakerSchedule));
                }
            }
            catch (err) {
                if (!err.status) {
                    throw err
                }
                return rejectWithValue(err.body)
            }
        }
    );

const userSlice = createSlice({
    name: "@@user",
    initialState,
    reducers: {
        resetUser(state) {
            state.user.publicID = initialState.user.publicID;
            state.user.token = initialState.user.token;
            state.isFreeUser = initialState.isFreeUser;
            state.scheduleId = initialState.scheduleId;
            state.testInfo = initialState.testInfo;
            state.isAnonymousUser = initialState.isAnonymousUser;
            state.isAuthenticated = initialState.isAuthenticated;
        },
        setParticipantID(state, action) {
            state.user.publicID = action.payload;
        },
        setUserToken(state, action) {
            state.user.token = action.payload
        },
        setScheduleId(state, action) {
            state.user.publicID = initialState.user.publicID;
            state.user.token = initialState.user.token;
            state.isFreeUser = initialState.isFreeUser;
            state.testInfo = initialState.testInfo;
            state.scheduleId = action.payload;
            state.isAnonymousUser = true;
            state.isAuthenticated = true;
        },
        loginAnonymous(state) {
            state.user.publicID = uuidv4();
            state.user.token = state.user.token ?? "";
            state.isAuthenticated = true;
        },
        loginSuccess(state, action) {
            state.user = action.payload;
        },
        createAccountSuccess(state, action) {
            state.user = action.payload;
        },
        saveCourseResult(state, action) {
            state.results.course = [...state.results.course, action.payload]
        },
        refreshCourseResult(state, action) {
            state.results.course = action.payload;
        },
        refreshImplicitBiasResult(state, action) {
            state.results.survey = action.payload;
        },
        setTestInfo(state, action: PayloadAction<TestTakerScheduleDTO>) {
            state.testInfo = action.payload;
            state.isAuthenticated = true;
            state.isFreeUser = false;
        }
    },
    extraReducers: builder => {

    }
});

export const { setTestInfo, setScheduleId, resetUser, setParticipantID, setUserToken, loginAnonymous, loginSuccess, createAccountSuccess, saveCourseResult, refreshCourseResult, refreshImplicitBiasResult } = userSlice.actions;
export {
    loginAction,
    createAccountAction,
    getUserPackages,
    getUserPackagesBySchedule
}
export default userSlice.reducer