/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CourseResultDTO
 */
export interface CourseResultDTO {
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    participantId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseResultDTO
     */
    score: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseResultDTO
     */
    passed: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CourseResultDTO
     */
    completedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseResultDTO
     */
    anonymousUser: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    scheduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    groupId?: string;
}

export function CourseResultDTOFromJSON(json: any): CourseResultDTO {
    return CourseResultDTOFromJSONTyped(json, false);
}

export function CourseResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CourseResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participantId': !exists(json, 'participantId') ? undefined : json['participantId'],
        'score': json['score'],
        'passed': json['passed'],
        'completedDate': (new Date(json['completedDate'])),
        'token': !exists(json, 'token') ? undefined : json['token'],
        'courseId': !exists(json, 'courseId') ? undefined : json['courseId'],
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
        'anonymousUser': json['anonymousUser'],
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
    };
}

export function CourseResultDTOToJSON(value?: CourseResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participantId': value.participantId,
        'score': value.score,
        'passed': value.passed,
        'completedDate': (value.completedDate.toISOString()),
        'token': value.token,
        'courseId': value.courseId,
        'feedback': value.feedback,
        'anonymousUser': value.anonymousUser,
        'scheduleId': value.scheduleId,
        'groupId': value.groupId,
    };
}


