/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GroupDTO,
    GroupDTOFromJSON,
    GroupDTOToJSON,
    GroupOverviewDTO,
    GroupOverviewDTOFromJSON,
    GroupOverviewDTOToJSON,
    GroupResultsDTO,
    GroupResultsDTOFromJSON,
    GroupResultsDTOToJSON,
} from '../models';

export interface GroupAddGroupRequest {
    groupDTO: GroupDTO;
}

export interface GroupDeleteGroupRequest {
    groupId: string;
}

export interface GroupGetGroupRequest {
    id: string | null;
}

export interface GroupUpdateGroupOverviewRequest {
    groupId: string | null;
    groupOverviewDTO: GroupOverviewDTO;
}

/**
 * 
 */
export class GroupApi extends runtime.BaseAPI {

    /**
     */
    async groupAddGroupRaw(requestParameters: GroupAddGroupRequest): Promise<runtime.ApiResponse<GroupDTO>> {
        if (requestParameters.groupDTO === null || requestParameters.groupDTO === undefined) {
            throw new runtime.RequiredError('groupDTO','Required parameter requestParameters.groupDTO was null or undefined when calling groupAddGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/Group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupDTOToJSON(requestParameters.groupDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDTOFromJSON(jsonValue));
    }

    /**
     */
    async groupAddGroup(requestParameters: GroupAddGroupRequest): Promise<GroupDTO> {
        const response = await this.groupAddGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async groupDeleteGroupRaw(requestParameters: GroupDeleteGroupRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling groupDeleteGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/Group`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.groupId as any,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async groupDeleteGroup(requestParameters: GroupDeleteGroupRequest): Promise<Blob> {
        const response = await this.groupDeleteGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async groupGetGroupRaw(requestParameters: GroupGetGroupRequest): Promise<runtime.ApiResponse<GroupDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupGetGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDTOFromJSON(jsonValue));
    }

    /**
     */
    async groupGetGroup(requestParameters: GroupGetGroupRequest): Promise<GroupDTO> {
        const response = await this.groupGetGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async groupGetGroupsRaw(): Promise<runtime.ApiResponse<Array<GroupResultsDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Group/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupResultsDTOFromJSON));
    }

    /**
     */
    async groupGetGroups(): Promise<Array<GroupResultsDTO>> {
        const response = await this.groupGetGroupsRaw();
        return await response.value();
    }

    /**
     */
    async groupGetGroupsWithResultsRaw(): Promise<runtime.ApiResponse<Array<GroupResultsDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Group/groups/results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupResultsDTOFromJSON));
    }

    /**
     */
    async groupGetGroupsWithResults(): Promise<Array<GroupResultsDTO>> {
        const response = await this.groupGetGroupsWithResultsRaw();
        return await response.value();
    }

    /**
     */
    async groupUpdateGroupOverviewRaw(requestParameters: GroupUpdateGroupOverviewRequest): Promise<runtime.ApiResponse<GroupOverviewDTO>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling groupUpdateGroupOverview.');
        }

        if (requestParameters.groupOverviewDTO === null || requestParameters.groupOverviewDTO === undefined) {
            throw new runtime.RequiredError('groupOverviewDTO','Required parameter requestParameters.groupOverviewDTO was null or undefined when calling groupUpdateGroupOverview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/Group/overview/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupOverviewDTOToJSON(requestParameters.groupOverviewDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupOverviewDTOFromJSON(jsonValue));
    }

    /**
     */
    async groupUpdateGroupOverview(requestParameters: GroupUpdateGroupOverviewRequest): Promise<GroupOverviewDTO> {
        const response = await this.groupUpdateGroupOverviewRaw(requestParameters);
        return await response.value();
    }

}
