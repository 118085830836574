import { UserApi, UserInfo, ConfigurationParameters as UserConfigParams, Configuration as UserConfig, UserCreateAccountRequest, UserLoginRequest, AuthenticatedModel, UserGetUserByTokenRequest, ImplicitBiasResultModel, UserSaveResultsRequest, UserPreviousResultsRequest, UserGenerateUrlTokenRequest } from '../../external-api/user-api';

import { getCookie } from 'src/utilities/cookies';
import { trackPromise } from 'react-promise-tracker';

const apiUrl = `${process.env.GATSBY_API_URL}`;
const userApiUrl = `${process.env.GATSBY_USER_API_URL}`;
const apiKey = `${process.env.GATSBY_OPENAPI_API_KEY}`;

function getApi() {
    const token = getCookie("token");
    const configParams: UserConfigParams = {
        basePath: userApiUrl,
        headers: {
            "apikey": apiKey,
            "Authorization": `Bearer ${token}`
        },
    };

    const configuration = new UserConfig(configParams);
    return new UserApi(configuration);
}

export async function getSurveyToken(userId: string) {
    const api = getApi();

    const request: UserGenerateUrlTokenRequest = {
        userId: userId
    };
    return await trackPromise(api.userGenerateUrlToken(request))
}

export async function getPreviousResults(userId: string) {
    const api = getApi();
    const request: UserPreviousResultsRequest = {
        userId: userId
    }

    return await trackPromise(api.userPreviousResults(request));
}

export async function createAccount(data: UserInfo) {
    const api = getApi();
    const request: UserCreateAccountRequest = {
        info: data
    };

    return await trackPromise(api.userCreateAccount(request));
}

export async function login(data: AuthenticatedModel) {
    const api = getApi();
    const request: UserLoginRequest = {
        info: data
    };

    return await trackPromise(api.userLogin(request));
}

export async function getToken(token: string) {
    const api = getApi();
    const request: UserGetUserByTokenRequest = {
        token: token
    };

    return await trackPromise(api.userGetUserByToken(request));
}

export async function addResultToUser(data: ImplicitBiasResultModel) {
    const api = getApi();
    const request: UserSaveResultsRequest = {
        info: data
    };

    return await trackPromise(api.userSaveResults(request));
}



