/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CourseResultDTO,
    CourseResultDTOFromJSON,
    CourseResultDTOToJSON,
    TestResultDTO,
    TestResultDTOFromJSON,
    TestResultDTOToJSON,
    TestSummaryDTO,
    TestSummaryDTOFromJSON,
    TestSummaryDTOToJSON,
    TestTakerScheduleDTO,
    TestTakerScheduleDTOFromJSON,
    TestTakerScheduleDTOToJSON,
} from '../models';

export interface TestTakerAddCourseResultsRequest {
    courseResultsDTO: CourseResultDTO;
}

export interface TestTakerAddPublicCourseResultsRequest {
    courseResultsDTO: CourseResultDTO;
}

export interface TestTakerAddPublicTestResultsRequest {
    testResultsDTO: TestResultDTO;
}

export interface TestTakerAddTestResultsRequest {
    testResultsDTO: TestResultDTO;
}

export interface TestTakerGetGroupDetailsRequest {
    token: string;
}

export interface TestTakerGetGroupDetailsByScheduleRequest {
    scheduleId: string;
}

/**
 * 
 */
export class TestTakerApi extends runtime.BaseAPI {

    /**
     */
    async testTakerAddCourseResultsRaw(requestParameters: TestTakerAddCourseResultsRequest): Promise<runtime.ApiResponse<CourseResultDTO>> {
        if (requestParameters.courseResultsDTO === null || requestParameters.courseResultsDTO === undefined) {
            throw new runtime.RequiredError('courseResultsDTO','Required parameter requestParameters.courseResultsDTO was null or undefined when calling testTakerAddCourseResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/TestTaker/AddCourseResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CourseResultDTOToJSON(requestParameters.courseResultsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CourseResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async testTakerAddCourseResults(requestParameters: TestTakerAddCourseResultsRequest): Promise<CourseResultDTO> {
        const response = await this.testTakerAddCourseResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async testTakerAddPublicCourseResultsRaw(requestParameters: TestTakerAddPublicCourseResultsRequest): Promise<runtime.ApiResponse<CourseResultDTO>> {
        if (requestParameters.courseResultsDTO === null || requestParameters.courseResultsDTO === undefined) {
            throw new runtime.RequiredError('courseResultsDTO','Required parameter requestParameters.courseResultsDTO was null or undefined when calling testTakerAddPublicCourseResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/TestTaker/AddPublicCourseResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CourseResultDTOToJSON(requestParameters.courseResultsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CourseResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async testTakerAddPublicCourseResults(requestParameters: TestTakerAddPublicCourseResultsRequest): Promise<CourseResultDTO> {
        const response = await this.testTakerAddPublicCourseResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async testTakerAddPublicTestResultsRaw(requestParameters: TestTakerAddPublicTestResultsRequest): Promise<runtime.ApiResponse<TestSummaryDTO>> {
        if (requestParameters.testResultsDTO === null || requestParameters.testResultsDTO === undefined) {
            throw new runtime.RequiredError('testResultsDTO','Required parameter requestParameters.testResultsDTO was null or undefined when calling testTakerAddPublicTestResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/TestTaker/AddPublicTestResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TestResultDTOToJSON(requestParameters.testResultsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TestSummaryDTOFromJSON(jsonValue));
    }

    /**
     */
    async testTakerAddPublicTestResults(requestParameters: TestTakerAddPublicTestResultsRequest): Promise<TestSummaryDTO> {
        const response = await this.testTakerAddPublicTestResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async testTakerAddTestResultsRaw(requestParameters: TestTakerAddTestResultsRequest): Promise<runtime.ApiResponse<TestSummaryDTO>> {
        if (requestParameters.testResultsDTO === null || requestParameters.testResultsDTO === undefined) {
            throw new runtime.RequiredError('testResultsDTO','Required parameter requestParameters.testResultsDTO was null or undefined when calling testTakerAddTestResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/TestTaker/AddTestResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TestResultDTOToJSON(requestParameters.testResultsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TestSummaryDTOFromJSON(jsonValue));
    }

    /**
     */
    async testTakerAddTestResults(requestParameters: TestTakerAddTestResultsRequest): Promise<TestSummaryDTO> {
        const response = await this.testTakerAddTestResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async testTakerGetGroupDetailsRaw(requestParameters: TestTakerGetGroupDetailsRequest): Promise<runtime.ApiResponse<TestTakerScheduleDTO>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling testTakerGetGroupDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/TestTaker/GetGroupDetails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.token as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TestTakerScheduleDTOFromJSON(jsonValue));
    }

    /**
     */
    async testTakerGetGroupDetails(requestParameters: TestTakerGetGroupDetailsRequest): Promise<TestTakerScheduleDTO> {
        const response = await this.testTakerGetGroupDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async testTakerGetGroupDetailsByScheduleRaw(requestParameters: TestTakerGetGroupDetailsByScheduleRequest): Promise<runtime.ApiResponse<TestTakerScheduleDTO>> {
        if (requestParameters.scheduleId === null || requestParameters.scheduleId === undefined) {
            throw new runtime.RequiredError('scheduleId','Required parameter requestParameters.scheduleId was null or undefined when calling testTakerGetGroupDetailsBySchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/TestTaker/GetGroupDetailsBySchedule`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.scheduleId as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TestTakerScheduleDTOFromJSON(jsonValue));
    }

    /**
     */
    async testTakerGetGroupDetailsBySchedule(requestParameters: TestTakerGetGroupDetailsByScheduleRequest): Promise<TestTakerScheduleDTO> {
        const response = await this.testTakerGetGroupDetailsByScheduleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async testTakerGetSummaryRaw(): Promise<runtime.ApiResponse<TestSummaryDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/TestTaker/GetSummary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TestSummaryDTOFromJSON(jsonValue));
    }

    /**
     */
    async testTakerGetSummary(): Promise<TestSummaryDTO> {
        const response = await this.testTakerGetSummaryRaw();
        return await response.value();
    }

}
