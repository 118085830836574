/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CourseResultModel,
    CourseResultModelFromJSON,
    CourseResultModelToJSON,
} from '../models';

export interface CoursePreviousResultsRequest {
    userId: string;
}

export interface CourseSaveResultsRequest {
    info: CourseResultModel;
}

/**
 * 
 */
export class CourseApi extends runtime.BaseAPI {

    /**
     */
    async coursePreviousResultsRaw(requestParameters: CoursePreviousResultsRequest): Promise<runtime.ApiResponse<Array<CourseResultModel>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling coursePreviousResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Course/PreviousResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userId as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CourseResultModelFromJSON));
    }

    /**
     */
    async coursePreviousResults(requestParameters: CoursePreviousResultsRequest): Promise<Array<CourseResultModel>> {
        const response = await this.coursePreviousResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async courseSaveResultsRaw(requestParameters: CourseSaveResultsRequest): Promise<runtime.ApiResponse<CourseResultModel>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling courseSaveResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Course/SaveResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CourseResultModelToJSON(requestParameters.info),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CourseResultModelFromJSON(jsonValue));
    }

    /**
     */
    async courseSaveResults(requestParameters: CourseSaveResultsRequest): Promise<CourseResultModel> {
        const response = await this.courseSaveResultsRaw(requestParameters);
        return await response.value();
    }

}
