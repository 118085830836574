/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CourseConfigurationDTO,
    CourseConfigurationDTOFromJSON,
    CourseConfigurationDTOFromJSONTyped,
    CourseConfigurationDTOToJSON,
    CourseResultDTO,
    CourseResultDTOFromJSON,
    CourseResultDTOFromJSONTyped,
    CourseResultDTOToJSON,
    TestConfigurationDTO,
    TestConfigurationDTOFromJSON,
    TestConfigurationDTOFromJSONTyped,
    TestConfigurationDTOToJSON,
    TestResultDTO,
    TestResultDTOFromJSON,
    TestResultDTOFromJSONTyped,
    TestResultDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface TestTakerScheduleDTO
 */
export interface TestTakerScheduleDTO {
    /**
     * 
     * @type {string}
     * @memberof TestTakerScheduleDTO
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestTakerScheduleDTO
     */
    scheduleId?: string;
    /**
     * 
     * @type {Array<TestConfigurationDTO>}
     * @memberof TestTakerScheduleDTO
     */
    testConfigurations?: Array<TestConfigurationDTO>;
    /**
     * 
     * @type {Array<CourseConfigurationDTO>}
     * @memberof TestTakerScheduleDTO
     */
    courseConfigurations?: Array<CourseConfigurationDTO>;
    /**
     * 
     * @type {string}
     * @memberof TestTakerScheduleDTO
     */
    participantId?: string;
    /**
     * 
     * @type {Array<CourseResultDTO>}
     * @memberof TestTakerScheduleDTO
     */
    courseResults?: Array<CourseResultDTO>;
    /**
     * 
     * @type {Array<TestResultDTO>}
     * @memberof TestTakerScheduleDTO
     */
    testResults?: Array<TestResultDTO>;
}

export function TestTakerScheduleDTOFromJSON(json: any): TestTakerScheduleDTO {
    return TestTakerScheduleDTOFromJSONTyped(json, false);
}

export function TestTakerScheduleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestTakerScheduleDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
        'testConfigurations': !exists(json, 'testConfigurations') ? undefined : ((json['testConfigurations'] as Array<any>).map(TestConfigurationDTOFromJSON)),
        'courseConfigurations': !exists(json, 'courseConfigurations') ? undefined : ((json['courseConfigurations'] as Array<any>).map(CourseConfigurationDTOFromJSON)),
        'participantId': !exists(json, 'participantId') ? undefined : json['participantId'],
        'courseResults': !exists(json, 'courseResults') ? undefined : ((json['courseResults'] as Array<any>).map(CourseResultDTOFromJSON)),
        'testResults': !exists(json, 'testResults') ? undefined : ((json['testResults'] as Array<any>).map(TestResultDTOFromJSON)),
    };
}

export function TestTakerScheduleDTOToJSON(value?: TestTakerScheduleDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupId': value.groupId,
        'scheduleId': value.scheduleId,
        'testConfigurations': value.testConfigurations === undefined ? undefined : ((value.testConfigurations as Array<any>).map(TestConfigurationDTOToJSON)),
        'courseConfigurations': value.courseConfigurations === undefined ? undefined : ((value.courseConfigurations as Array<any>).map(CourseConfigurationDTOToJSON)),
        'participantId': value.participantId,
        'courseResults': value.courseResults === undefined ? undefined : ((value.courseResults as Array<any>).map(CourseResultDTOToJSON)),
        'testResults': value.testResults === undefined ? undefined : ((value.testResults as Array<any>).map(TestResultDTOToJSON)),
    };
}


