import palette from '../palette';

export default {
    root: {
        "&$disabled": {
            "backgroundColor": " rgba(0, 0, 0, 0.12) !important",
            "borderColor": "transparent"
        }
    },
    contained: {
        display: "block",
        margin: "auto auto 0rem auto",
        padding: "0.675em 2.75em",
        fontSize: "1rem",
        fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        fontWeight: 600,
        letterSpacing: "-0.015em",
        border: "0.125rem solid" + palette.orangeMain,

        '&:disabled': {
            borderColor: "transparent",
        },
    },
    containedPrimary: {
        border: "0.125rem solid" + palette.orangeDarker,

        '&:hover, &:focus': {
            backgroundColor: palette.orangeDarker + " !important",
            borderColor: palette.orangeLight,
        },
        '&:disabled': {
            backgroundColor: " rgba(0, 0, 0, 0.12) !important",
            borderColor: "transparent",
        },
    },
    containedSecondary: {
        padding: "0.625rem 1.875rem",
        fontSize: "0.8125rem",

        '&:hover, &:focus': {
            backgroundColor: palette.whiteOff + " !important",
            borderColor: palette.orangeDarker,
        },
    },
    label: {

    },
};
