import { IQuestionType } from "src/type/globals";
import { ContentfulAssessmentAnswerContainer, ContentfulAssessmentOptionContainer, ContentfulAsset, Maybe } from "types/graphql-types";

import _ from "lodash"
/**
 * Standard shuffle algorithm
 */
export function randomizeOptionList<T>(list: any[]) : T[]{
    let shuffledList = _.uniqBy(_.cloneDeep(list), (x) => x?.id);

    for (let i = shuffledList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }
    return shuffledList as T[];
}


