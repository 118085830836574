/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessTokenAllOf,
    AccessTokenAllOfFromJSON,
    AccessTokenAllOfFromJSONTyped,
    AccessTokenAllOfToJSON,
    IdentityOfIntegerAndString,
    IdentityOfIntegerAndStringFromJSON,
    IdentityOfIntegerAndStringFromJSONTyped,
    IdentityOfIntegerAndStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {number}
     * @memberof AccessToken
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof AccessToken
     */
    dateCreated: Date;
    /**
     * 
     * @type {Date}
     * @memberof AccessToken
     */
    dateModified: Date;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    token?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccessToken
     */
    expirationDate: Date;
}

export function AccessTokenFromJSON(json: any): AccessToken {
    return AccessTokenFromJSONTyped(json, false);
}

export function AccessTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dateCreated': (new Date(json['dateCreated'])),
        'dateModified': (new Date(json['dateModified'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'expirationDate': (new Date(json['expirationDate'])),
    };
}

export function AccessTokenToJSON(value?: AccessToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dateCreated': (value.dateCreated.toISOString()),
        'dateModified': (value.dateModified.toISOString()),
        'userId': value.userId,
        'token': value.token,
        'expirationDate': (value.expirationDate.toISOString()),
    };
}


