/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnalyzeImplicitBiasAnalysisInfo,
    AnalyzeImplicitBiasAnalysisInfoFromJSON,
    AnalyzeImplicitBiasAnalysisInfoToJSON,
    ImplicitBiasResultDTO,
    ImplicitBiasResultDTOFromJSON,
    ImplicitBiasResultDTOToJSON,
} from '../models';

export interface AnalysisAnalyzeRequest {
    info: AnalyzeImplicitBiasAnalysisInfo;
}

/**
 * 
 */
export class AnalysisApi extends runtime.BaseAPI {

    /**
     */
    async analysisAnalyzeRaw(requestParameters: AnalysisAnalyzeRequest): Promise<runtime.ApiResponse<ImplicitBiasResultDTO>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling analysisAnalyze.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Analysis`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyzeImplicitBiasAnalysisInfoToJSON(requestParameters.info),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImplicitBiasResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async analysisAnalyze(requestParameters: AnalysisAnalyzeRequest): Promise<ImplicitBiasResultDTO> {
        const response = await this.analysisAnalyzeRaw(requestParameters);
        return await response.value();
    }

}
