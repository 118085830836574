/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    publicID?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    token?: string;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'emailAddress': json['emailAddress'],
        'password': json['password'],
        'publicID': !exists(json, 'publicID') ? undefined : json['publicID'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'password': value.password,
        'publicID': value.publicID,
        'token': value.token,
    };
}


