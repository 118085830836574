import palette from '../palette';

export default {
    // root: {
    //     border: `0.0625rem solid ${palette.orangeMain}`,
    //     color: palette.orangeMain
    // },
    // label: {
    //     margin: "0 !important",
    //     color: palette.orangeMain,
    //     textAlign: 'center'
    // }
};
