import { ImplicitBiasInput } from "external-api/service-api"

export const Paths = {
  HOME: "/",
  ABOUT: "/about",
  BACKGROUND: "/background",
  PRIVACY: "/privacy",
  SURVEY: "/app/surveys",
  SELECTION: "/app/selection",
  SURVEY_RESULTS: "/app/survey-results",
  COURSE: "/app/courses",
  COURSE_RESULTS: "/app/course-results",
  LOGIN: "/login/",
  CREATE_ACCOUNT: "/create-account",
  PREVIOUS_RESULTS: "/app/previous-results",
}

export const ParticipantKey = "ParticipantId"

export const SkipPractice: ImplicitBiasInput[] = [
  {
    responseTimeMilliseconds: 0,
    blockID: 0,
    trialID: 0,
    correct: true,
  },
]

export function generatePracticeResponses() {
  const inputs: ImplicitBiasInput[] = [...Array(20)].map((input, index) => ({
    responseTimeMilliseconds: 0,
    blockID: 1,
    trialID: index + 1,
    correct: true,
  }))

  return inputs
}
