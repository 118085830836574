import palette from '../palette';

export default {
    root: {
        color: palette.orangeDark,
    },
    underlineHover: {
        textDecoration: "underline",

        '&:hover, &:focus': {
            textDecoration: "none",
        }
    }
  };
  