
import { getCookie } from 'src/utilities/cookies';
import { trackPromise } from 'react-promise-tracker';
import { Configuration, ConfigurationParameters, CourseResultDTO, TestResultDTO, TestTakerAddCourseResultsRequest, TestTakerAddPublicCourseResultsRequest, TestTakerAddPublicTestResultsRequest, TestTakerAddTestResultsRequest, TestTakerApi, TestTakerGetGroupDetailsByScheduleRequest, TestTakerGetGroupDetailsRequest, TestTakerScheduleDTO } from '../../external-api/license-api';

const userApiUrl = `${process.env.GATSBY_LICENSE_API_URL}`;
const apiKey = `${process.env.GATSBY_OPENAPI_API_KEY}`;

function getApi() {
    const token = getCookie("token");
    const configParams: ConfigurationParameters = {
        basePath: userApiUrl,
        headers: {
            "apikey": apiKey,
            "Authorization": `Bearer ${token}`
        },
    };

    const configuration = new Configuration(configParams);
    return new TestTakerApi(configuration);
}

export async function addCourseResultToUser(courseResults: CourseResultDTO) {
    const api = getApi();
    const request: TestTakerAddCourseResultsRequest = {
        courseResultsDTO: courseResults

        // {
        //     participantId: courseResults.participantId,
        //     passed: courseResults.passed,
        //     score: courseResults.score,
        //     completedDate: new Date(),
        //     courseId: courseResults.courseId,
        // }
    };

    return await trackPromise(api.testTakerAddCourseResults(request));
}

export async function addPublicCourseResultToUser(publicCourseResults: CourseResultDTO) {
    const api = getApi();
    const request: TestTakerAddPublicCourseResultsRequest = {
        courseResultsDTO: publicCourseResults

        // {
        //     passed: publicCourseResults.passed,
        //     score: publicCourseResults.score,
        //     completedDate: new Date(),
        //     courseId: publicCourseResults.courseId
        // }
    };

    return await trackPromise(api.testTakerAddPublicCourseResults(request));
}
export async function addTestResultsToUser(testResults: TestResultDTO) {
    const api = getApi();
    const request: TestTakerAddTestResultsRequest = {
        testResultsDTO: testResults
    }

    return await trackPromise(api.testTakerAddTestResults(request));
}

export async function addPublicTestResultsToUser(testResults: TestResultDTO) {
    const api = getApi();
    const request: TestTakerAddPublicTestResultsRequest = {
        testResultsDTO: testResults
    }

    return await trackPromise(api.testTakerAddPublicTestResults(request));
}

export async function fetchPackageConfiguration(token: string) {
    const api = getApi();
    const request: TestTakerGetGroupDetailsRequest = {
        token: token
    }

    return await trackPromise(api.testTakerGetGroupDetails(request));
}

export async function fetchPackageConfigurationBySchedule(scheduleId: string) {
    const api = getApi();
    const request: TestTakerGetGroupDetailsByScheduleRequest = {
        scheduleId: scheduleId
    }

    return await trackPromise(api.testTakerGetGroupDetailsBySchedule(request));
}


export async function getResultsTest() {
    const api = getApi();
    return await trackPromise(api.testTakerGetSummary());
}
