
export default {
  "@global": {
    html: {
      fontSize: 16,

      '@media (min-width: 600px)': {
        fontSize: "2.667vw"
      },
       
      '@media (min-width: 1280px)': {
        fontSize: "2.4vh"
      },

/*

      '@media (min-width: 768px)': {
        fontSize: 24
      },

      '@media (min-width: 960px)': {
        fontSize: 28
      },

      '@media (min-width: 1280px)': {
        fontSize: 32
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 20
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 24
      }
      */
    },
  }
  };
  