/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImplicitBiasInputDTO,
    ImplicitBiasInputDTOFromJSON,
    ImplicitBiasInputDTOFromJSONTyped,
    ImplicitBiasInputDTOToJSON,
    ImplicitBiasInterpretation,
    ImplicitBiasInterpretationFromJSON,
    ImplicitBiasInterpretationFromJSONTyped,
    ImplicitBiasInterpretationToJSON,
    ImplicitBiasInterpretationDirection,
    ImplicitBiasInterpretationDirectionFromJSON,
    ImplicitBiasInterpretationDirectionFromJSONTyped,
    ImplicitBiasInterpretationDirectionToJSON,
    TestResultDetailsDTO,
    TestResultDetailsDTOFromJSON,
    TestResultDetailsDTOFromJSONTyped,
    TestResultDetailsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface TestResultDTO
 */
export interface TestResultDTO {
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    testId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    participantId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    scheduleId?: string;
    /**
     * 
     * @type {ImplicitBiasInterpretation}
     * @memberof TestResultDTO
     */
    interpretation: ImplicitBiasInterpretation;
    /**
     * 
     * @type {ImplicitBiasInterpretationDirection}
     * @memberof TestResultDTO
     */
    direction: ImplicitBiasInterpretationDirection;
    /**
     * 
     * @type {TestResultDetailsDTO}
     * @memberof TestResultDTO
     */
    testResultDetails?: TestResultDetailsDTO;
    /**
     * 
     * @type {Array<ImplicitBiasInputDTO>}
     * @memberof TestResultDTO
     */
    rawInputs?: Array<ImplicitBiasInputDTO>;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    implicitBiasSurveyInputs?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TestResultDTO
     */
    anonymousUser: boolean;
}

export function TestResultDTOFromJSON(json: any): TestResultDTO {
    return TestResultDTOFromJSONTyped(json, false);
}

export function TestResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'testId': !exists(json, 'testId') ? undefined : json['testId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'participantId': !exists(json, 'participantId') ? undefined : json['participantId'],
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
        'interpretation': ImplicitBiasInterpretationFromJSON(json['interpretation']),
        'direction': ImplicitBiasInterpretationDirectionFromJSON(json['direction']),
        'testResultDetails': !exists(json, 'testResultDetails') ? undefined : TestResultDetailsDTOFromJSON(json['testResultDetails']),
        'rawInputs': !exists(json, 'rawInputs') ? undefined : ((json['rawInputs'] as Array<any>).map(ImplicitBiasInputDTOFromJSON)),
        'implicitBiasSurveyInputs': !exists(json, 'implicitBiasSurveyInputs') ? undefined : json['implicitBiasSurveyInputs'],
        'anonymousUser': json['anonymousUser'],
    };
}

export function TestResultDTOToJSON(value?: TestResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'testId': value.testId,
        'groupId': value.groupId,
        'participantId': value.participantId,
        'scheduleId': value.scheduleId,
        'interpretation': ImplicitBiasInterpretationToJSON(value.interpretation),
        'direction': ImplicitBiasInterpretationDirectionToJSON(value.direction),
        'testResultDetails': TestResultDetailsDTOToJSON(value.testResultDetails),
        'rawInputs': value.rawInputs === undefined ? undefined : ((value.rawInputs as Array<any>).map(ImplicitBiasInputDTOToJSON)),
        'implicitBiasSurveyInputs': value.implicitBiasSurveyInputs,
        'anonymousUser': value.anonymousUser,
    };
}


