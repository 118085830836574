/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImplicitBiasResultDirection,
    ImplicitBiasResultDirectionFromJSON,
    ImplicitBiasResultDirectionFromJSONTyped,
    ImplicitBiasResultDirectionToJSON,
    ImplicitBiasResultInterpretation,
    ImplicitBiasResultInterpretationFromJSON,
    ImplicitBiasResultInterpretationFromJSONTyped,
    ImplicitBiasResultInterpretationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImplicitBiasResultDTO
 */
export interface ImplicitBiasResultDTO {
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    score: number;
    /**
     * 
     * @type {ImplicitBiasResultInterpretation}
     * @memberof ImplicitBiasResultDTO
     */
    interpretation: ImplicitBiasResultInterpretation;
    /**
     * 
     * @type {ImplicitBiasResultDirection}
     * @memberof ImplicitBiasResultDTO
     */
    direction: ImplicitBiasResultDirection;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    highResponseTimeCount: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    lowResponseTimePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    practiceSTD: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    testSTD: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    practiceMeanDiff: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    testMeanDiff: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    practiceEffectSize: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasResultDTO
     */
    testEffectSize: number;
}

export function ImplicitBiasResultDTOFromJSON(json: any): ImplicitBiasResultDTO {
    return ImplicitBiasResultDTOFromJSONTyped(json, false);
}

export function ImplicitBiasResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImplicitBiasResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'interpretation': ImplicitBiasResultInterpretationFromJSON(json['interpretation']),
        'direction': ImplicitBiasResultDirectionFromJSON(json['direction']),
        'highResponseTimeCount': json['highResponseTimeCount'],
        'lowResponseTimePercentage': json['lowResponseTimePercentage'],
        'practiceSTD': json['practiceSTD'],
        'testSTD': json['testSTD'],
        'practiceMeanDiff': json['practiceMeanDiff'],
        'testMeanDiff': json['testMeanDiff'],
        'practiceEffectSize': json['practiceEffectSize'],
        'testEffectSize': json['testEffectSize'],
    };
}

export function ImplicitBiasResultDTOToJSON(value?: ImplicitBiasResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'interpretation': ImplicitBiasResultInterpretationToJSON(value.interpretation),
        'direction': ImplicitBiasResultDirectionToJSON(value.direction),
        'highResponseTimeCount': value.highResponseTimeCount,
        'lowResponseTimePercentage': value.lowResponseTimePercentage,
        'practiceSTD': value.practiceSTD,
        'testSTD': value.testSTD,
        'practiceMeanDiff': value.practiceMeanDiff,
        'testMeanDiff': value.testMeanDiff,
        'practiceEffectSize': value.practiceEffectSize,
        'testEffectSize': value.testEffectSize,
    };
}


