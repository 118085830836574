import React from 'react';
import { Provider } from 'react-redux';
import store from 'src/state/store';
import Notifier from "src/components/snack-bar/notifier";
import GlobalLoader from "src/components/loader/loader";
import { SnackbarProvider } from 'notistack';
export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
        <GlobalLoader />
        <Notifier/>
        {element}
      </SnackbarProvider>
    </Provider>
  ); 
};
