/* tslint:disable */
/* eslint-disable */
/**
 * SI Implicit-Bias API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImplicitBiasInput,
    ImplicitBiasInputFromJSON,
    ImplicitBiasInputFromJSONTyped,
    ImplicitBiasInputToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnalyzeImplicitBiasAnalysisInfo
 */
export interface AnalyzeImplicitBiasAnalysisInfo {
    /**
     * 
     * @type {Array<ImplicitBiasInput>}
     * @memberof AnalyzeImplicitBiasAnalysisInfo
     */
    rawInputs: Array<ImplicitBiasInput>;
}

export function AnalyzeImplicitBiasAnalysisInfoFromJSON(json: any): AnalyzeImplicitBiasAnalysisInfo {
    return AnalyzeImplicitBiasAnalysisInfoFromJSONTyped(json, false);
}

export function AnalyzeImplicitBiasAnalysisInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyzeImplicitBiasAnalysisInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rawInputs': ((json['rawInputs'] as Array<any>).map(ImplicitBiasInputFromJSON)),
    };
}

export function AnalyzeImplicitBiasAnalysisInfoToJSON(value?: AnalyzeImplicitBiasAnalysisInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rawInputs': ((value.rawInputs as Array<any>).map(ImplicitBiasInputToJSON)),
    };
}


